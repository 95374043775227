import { SVGProps } from 'react'

const VisaIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 226 167" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect
            x="21.3229"
            y="21.3229"
            width="182.562"
            height="124.354"
            rx="14.5521"
            fill="white"
            stroke="#D9D9D9"
            strokeWidth="2.64583"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M76.2253 106.033H65.0053L56.5916 73.9349C56.1923 72.4584 55.3444 71.153 54.0971 70.5378C50.9844 68.9917 47.5543 67.7613 43.8125 67.1407V65.9049H61.8871C64.3816 65.9049 66.2526 67.7613 66.5644 69.9172L70.9299 93.071L82.1444 65.9049H93.0526L76.2253 106.033ZM99.2891 106.033H88.6927L97.4182 65.9049H108.015L99.2891 106.033ZM121.724 77.0216C122.035 74.8603 123.906 73.6245 126.089 73.6245C129.519 73.3142 133.256 73.9348 136.374 75.4755L138.245 66.8356C135.126 65.5998 131.696 64.9792 128.584 64.9792C118.299 64.9792 110.815 70.5377 110.815 78.252C110.815 84.1207 116.116 87.2022 119.858 89.0585C123.906 90.9095 125.466 92.1453 125.154 93.9964C125.154 96.7729 122.035 98.0087 118.923 98.0087C115.181 98.0087 111.439 97.0832 108.015 95.5371L106.144 104.182C109.885 105.723 113.934 106.344 117.675 106.344C129.207 106.649 136.374 101.095 136.374 92.7606C136.374 82.2643 121.724 81.6491 121.724 77.0216ZM173.458 106.033L165.045 65.9049H156.007C154.136 65.9049 152.266 67.1407 151.642 68.9917L136.062 106.033H146.97L149.147 100.17H162.55L163.797 106.033H173.458ZM157.566 76.7111L160.679 91.8349H151.954L157.566 76.7111Z"
            fill="#172B85"
        />
    </svg>
)

const MastercardIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 226 167" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect
            x="21.3229"
            y="21.3229"
            width="182.562"
            height="124.354"
            rx="14.5521"
            fill="black"
            stroke="#D9D9D9"
            strokeWidth="2.64583"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M113.927 110.789C107.622 116.23 99.4425 119.515 90.5049 119.515C70.5624 119.515 54.3958 103.161 54.3958 82.9865C54.3958 62.8125 70.5624 46.4583 90.5049 46.4583C99.4425 46.4583 107.622 49.7431 113.927 55.1844C120.232 49.7431 128.412 46.4583 137.349 46.4583C157.292 46.4583 173.458 62.8126 173.458 82.9866C173.458 103.161 157.292 119.515 137.349 119.515C128.412 119.515 120.232 116.23 113.927 110.789Z"
            fill="#ED0006"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M113.927 110.789C121.691 104.089 126.614 94.1193 126.614 82.9865C126.614 71.8538 121.691 61.8843 113.927 55.1843C120.232 49.743 128.412 46.4583 137.349 46.4583C157.292 46.4583 173.458 62.8125 173.458 82.9865C173.458 103.161 157.292 119.515 137.349 119.515C128.412 119.515 120.232 116.23 113.927 110.789Z"
            fill="#F9A000"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M113.927 110.789C121.691 104.089 126.614 94.1193 126.614 82.9866C126.614 71.854 121.691 61.8845 113.927 55.1846C106.163 61.8845 101.24 71.854 101.24 82.9866C101.24 94.1193 106.163 104.089 113.927 110.789Z"
            fill="#FF5E00"
        />
    </svg>
)

const AmexIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 226 167" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect
            x="21.3229"
            y="21.3229"
            width="182.562"
            height="124.354"
            rx="14.5521"
            fill="#1F72CD"
            stroke="#D9D9D9"
            strokeWidth="2.64583"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M53.2035 64.9792L35.875 103.327H56.6196L59.1913 97.2124H65.0697L67.6415 103.327H90.4755V98.6601L92.5101 103.327H104.322L106.356 98.5614V103.327H153.845L159.619 97.3712L165.026 103.327L189.417 103.376L172.034 84.26L189.417 64.9792H165.404L159.783 70.8246L154.547 64.9792H102.885L98.4493 74.877L93.909 64.9792H73.2077V69.487L70.9048 64.9792H53.2035ZM124.118 70.4246H151.388L159.729 79.434L168.338 70.4246H176.679L164.006 84.2545L176.679 97.9251H167.96L159.619 88.811L150.966 97.9251H124.118V70.4246ZM130.852 81.1454V76.1222V76.1174H147.868L155.293 84.1507L147.539 92.228H130.852V86.7441H145.729V81.1454H130.852ZM57.2174 70.4246H67.3293L78.8233 96.4281V70.4246H89.9005L98.7782 89.069L106.96 70.4246H117.982V97.9413H111.275L111.221 76.3794L101.443 97.9413H95.4439L85.6117 76.3794V97.9413H71.8148L69.1992 91.7724H55.0679L52.4577 97.9359H45.0655L57.2174 70.4246ZM57.4809 86.0693L62.1366 75.08L66.7869 86.0693H57.4809Z"
            fill="white"
        />
    </svg>
)

export const CARD_BRAND_MAP = {
    visa: VisaIcon,
    mastercard: MastercardIcon,
    amex: AmexIcon,
} as const

export type CardBrand = keyof typeof CARD_BRAND_MAP

export const CARD_BRANDS = Object.keys(CARD_BRAND_MAP)
