import { useCallback, useState } from 'react'
import Link from 'next/link'
import { Subscription } from 'lib/models/subscription'

import { Modal, DropdownButton } from 'ds/components'
import { RecordPaymentFormLoader } from '../RecordPaymentFormLoader'
import { ArchiveInvoiceModal } from './ArchiveInvoiceModal'
import { SendNotificationModal } from '../SendNotificationModal'
import { RetryFailedAutoChargePaymentModal } from '../RetryFailedAutoChargePaymentModal'

type Props = {
    invoice: any
    canEdit: boolean
    canDelete: boolean
    canCreatePayment: boolean
    subscription?: Subscription
}
export const InvoiceActionsButton = ({
    invoice,
    subscription,
    canEdit,
    canDelete,
    canCreatePayment,
}: Props) => {
    const [showModal, setShowModal] = useState('')

    const closeModal = useCallback(() => setShowModal(''), [])

    // Option to retry failed payment is available if:
    // User is in subscription page, latest payment failed
    // Invoice belongs to a subscription that has auto-charged enabled
    // Option is available in failed invoices in subscription page
    const canRetryFailedAutoChargePayment =
        subscription &&
        invoice.ecart_latest_payment_attempt_status === 'declined' &&
        !invoice.settled_date &&
        subscription.ecart_auto_charge_config_id &&
        subscription.ecart_payment_method_id

    return (
        <div>
            <DropdownButton size="sm" variant="secondaryText" icon="three-dots">
                {canRetryFailedAutoChargePayment ? (
                    <a onClick={() => setShowModal('retry-payment')}>Reintentar pago</a>
                ) : null}
                {!invoice.settled_date ? (
                    <a onClick={() => setShowModal('send')}>Enviar recordatorio</a>
                ) : null}
                {canCreatePayment && !invoice.settled_date ? (
                    <a onClick={() => setShowModal('payment')}>Registrar pago</a>
                ) : null}
                <Link href={`/invoices/${invoice.invoice_id}/sales-order`}>
                    {invoice.settled_date ? 'Recibo' : 'Nota de venta'}
                </Link>
                <Link href={`/invoices/${invoice.invoice_id}/cfdi`}>CFDIs</Link>
                {canEdit && !invoice.settled_date ? (
                    <Link href={`/invoices/${invoice.invoice_id}/payment-plan`}>
                        Plan de pago
                    </Link>
                ) : null}
                {canEdit ? (
                    <Link href={`/invoices/${invoice.invoice_id}/edit`}>Editar</Link>
                ) : null}
                {canDelete ? (
                    <a className="danger-500" onClick={() => setShowModal('archive')}>
                        Borrar
                    </a>
                ) : null}
            </DropdownButton>
            {showModal === 'send' ? (
                <SendNotificationModal
                    invoiceId={invoice.invoice_id}
                    onClose={closeModal}
                    title="Enviar recordatorio"
                />
            ) : showModal === 'payment' ? (
                <Modal title="Registrar pago" onClose={closeModal}>
                    <RecordPaymentFormLoader
                        customer_id={invoice.customer_id}
                        invoices={[invoice]}
                        onSuccess={closeModal}
                    />
                </Modal>
            ) : showModal === 'archive' ? (
                <ArchiveInvoiceModal invoice={invoice} closeModal={closeModal} />
            ) : showModal === 'retry-payment' ? (
                <RetryFailedAutoChargePaymentModal
                    invoice={invoice}
                    subscription={subscription!}
                    closeModal={closeModal}
                />
            ) : null}
        </div>
    )
}
