import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { Button, ErrorBanner, Modal } from 'ds/components'
import { castDecimal } from 'lib/utils/castDecimal'
import { formatCurrency } from 'lib/utils/formatCurrency'
import { CardBrandIcon } from './CardBrandIcon'
import { capitalizeFirstLetter } from 'lib/utils/capitalizaFirstLetter'
import { Subscription } from 'lib/models/subscription'

import { mutationOnError, mutationOnSuccess } from 'lib/utils/mutationUtils'
import { invoiceService } from 'lib/services/invoiceService'
import { Invoice } from 'lib/models/invoice'

type Props = {
    invoice: Invoice
    subscription: Subscription

    closeModal: () => void
}

export const RetryFailedAutoChargePaymentModal = ({
    invoice,
    subscription,
    closeModal,
}: Props) => {
    const queryClient = useQueryClient()

    const { mutate, isPending } = useMutation({
        mutationFn: () =>
            invoiceService.postRetryFailedAutoChargePayment({
                invoice_id: invoice.invoice_id,
            }),
        onError: mutationOnError(),
        onSuccess: mutationOnSuccess({
            onSuccess: ({ message }) => {
                toast.success(message)
                queryClient.invalidateQueries({ queryKey: ['invoice'] })
                queryClient.invalidateQueries({ queryKey: ['subscription'] })
                closeModal()
            },
        }),
    })

    const amountDue = castDecimal(invoice.amount_total).minus(
        castDecimal(invoice.amount_paid)
    )

    // Extra check to avoid page breaking from undefined
    if (!subscription)
        return <ErrorBanner>Ha ocurrido un error: no hay un cobro recurrente</ErrorBanner>

    return (
        <Modal title="Reintentar pago fallido" size="md" onClose={closeModal}>
            <div className="stacked-md">
                <div className="stacked-md">
                    <h5>Por Cobrar</h5>
                    <p>{formatCurrency(amountDue)}</p>
                </div>

                <div className="stacked-md">
                    <h5>Método de Pago</h5>
                    <div className="d-flex align-items-center gap-2">
                        <CardBrandIcon
                            brand={subscription.ecart_payment_method_card_brand!}
                            size="md"
                        />
                        <span>
                            {capitalizeFirstLetter(
                                subscription.ecart_payment_method_card_brand!
                            )}
                        </span>
                        <span>•••• {subscription.ecart_payment_method_last_four}</span>
                    </div>
                </div>
                <p>
                    Se le enviara un recibo de pago a{' '}
                    <span className="primary-text">{invoice.customer_ecart_email}</span>
                </p>
            </div>
            <Button
                variant="secondary"
                className="inline-md"
                onClick={() => closeModal()}
            >
                Cancelar
            </Button>
            <Button className="inline-md" onClick={() => mutate({})}>
                {isPending ? 'Reintendando...' : 'Reintentar'}
            </Button>
        </Modal>
    )
}
