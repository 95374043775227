import cx from 'classnames'
import styles from './style.module.scss'
import { CARD_BRAND_MAP, CardBrand } from './icons'

type CardBrandIconSize = 'sm' | 'md' | 'lg'

type Props = {
    brand: CardBrand
    size?: CardBrandIconSize
    className?: string
}

export const CardBrandIcon = ({ brand, size = 'md', className = '' }: Props) => {
    const Icon = CARD_BRAND_MAP[brand]

    if (!Icon) return null

    return (
        <div className={cx(styles.cardBrandIcon, styles[size], className)}>
            <Icon />
        </div>
    )
}
